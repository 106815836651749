import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Login from "./components/login/Login";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import PageNotFound from "./components/shared/PageNotFound";
import Home from "./components/home";
import SearchAsure from "./components/asureservice/SearchAsure";
import ReportDownload from "./components/report/download/ReportDownload";
import SearchReports from "./components/report/searchreports";
import DelaconReportsUpload from "./components/report/delacon-reports";
import WeeklyReport from "./components/report/weeklyreport";

const Routes = (isAuthenticated) => {
  return (
    <div>
      <RouterRoutes>
        <Route exact path="/login" component={Login} />
        <Route element={<AuthenticatedRoute isAuthenticated={isAuthenticated} />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/monthly-report" element={<ReportDownload />} />
          <Route exact path="/weekly-report" element={<WeeklyReport />} />
          <Route exact path="/search-reports" element={<SearchReports />} />
          <Route exact path="/delacon-reports-upload" element={<DelaconReportsUpload />} />
          {/* <Route exact path="/monthly-report-download" element={<WeeklyReport />} /> */}
          <Route exact path="/asure-service" element={<SearchAsure />} />
        </Route>
        <Route component={PageNotFound} />
        {/* <UnauthenticatedRoute exact path="/signup">
          <Signup />
        </UnauthenticatedRoute> */}
      </RouterRoutes>
    </div>
  );
};

export default Routes;
