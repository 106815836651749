import React, {useEffect, useState} from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Routes from './Routes';
import * as AuthService from "./services/auth.service";
import {Link, NavLink, useLocation} from "react-router-dom";
import Signout from './components/signout/Signout';
import Cookies from 'js-cookie';
import {idTokenKey, accessTokenKey, currentUserKey, refreshTokenKey, tokenExpiredAt} from './const'
import AsureServices from './services/asure.service';

function App() {
    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');
    const [isAuthen, setIsAuthen] = useState(false);
    const [currentUser, setCurrentUser] = useState(JSON.parse(AuthService.getCurrentUser()));

    AsureServices.ping();
    if (!AuthService.isAuthenticated()) {
        AuthService.oauth2Token(code).then((result) => {
            Cookies.set(idTokenKey, result?.data?.id_token);
            Cookies.set(accessTokenKey, result?.data?.access_token);
            Cookies.set(refreshTokenKey, result?.data?.refresh_token);
            const d = new Date();
            Cookies.set(tokenExpiredAt, d.getTime() + result?.data?.expires_in * 1000);
            AuthService.setCurrentUser().then((result) => {
                Cookies.set(currentUserKey, JSON.stringify(result?.data));
                setCurrentUser(result?.data);
            })
                .catch((err) => {
                    // Do somthing
                });
            setIsAuthen(true);
        })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        // setIsAuthen(AuthService.isAuthenticated());
        // if (!isAuthen) {
        //   AuthService.oauth2Token(code).then((result) => {
        //     Cookies.set(idTokenKey, result?.data?.id_token);
        //     Cookies.set(accessTokenKey, result?.data?.access_token);
        //     Cookies.set(refreshTokenKey, result?.data?.refresh_token);
        //     const d = new Date();
        //     Cookies.set(tokenExpiredAt, d.getTime() + result?.data?.expires_in * 1000);
        //     AuthService.setCurrentUser().then((result) => {
        //       Cookies.set(currentUserKey, result?.data);
        //       setCurrentUser(result?.data);
        //     })
        //     .catch((err) => {
        //       // Do somthing
        //     });
        //     setIsAuthen(true);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        // }
        // AsureServices.ping();
    }, []);

    return (
        <div className="App">

            <nav className="navbar navbar-expand navbar-dark bg-dark my-navbar">

                <Link to={"/"} className="navbar-brand">
                    Asure Toolbox
                </Link>
                <div className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <NavLink to={"/home"} className="nav-link">
                            Home
                        </NavLink>
                    </li>
                    <li className="dropdown nav-item">
                        <button className="nav-item nav-link">Report</button>
                        <div className="dropdown-content">
                            <NavLink to={"/monthly-report"} className="nav-item">
                                Monthly Report
                            </NavLink>
                            <NavLink to={"/weekly-report"} className="nav-item">
                                Weekly Report
                            </NavLink>
                            <NavLink to={"/search-reports"} className="nav-item">
                                Search Reports
                            </NavLink>
                            <NavLink to={"/delacon-reports-upload"} className="nav-item">
                                Delacon Reports Upload
                            </NavLink>
                        </div>
                    </li>
                    <li className="nav-item">
                        <NavLink to={"/asure-service"} className="nav-link">
                            Services
                        </NavLink>
                    </li>
                </div>
                <div className="navbar-nav ml-auto">
                    <li>
                        <div className="person-email">
                            Welcome, {currentUser?.email}
                        </div>
                    </li>
                    <li className="nav-item">
                        <Signout/>
                    </li>
                </div>
            </nav>
            <div>
                <Routes isAuthenticated={isAuthen}/>
            </div>

        </div>
    );
}

// export default withAuthenticator(App);
export default App;
